<template>
  <v-icon color="red">mdi-close-circle</v-icon>
</template>

<script>
export default {
    name: 'NoRedIcon'
}
</script>

<style>

</style>