<template>
    <div>
        <v-card-title class="caption">USER MENU 
            <!-- <v-btn color="primary" x-small text @click="showMenu = !showMenu"><v-icon>mdi-menu-swap</v-icon>Hide/Show Menu</v-btn> -->
        </v-card-title>
        <v-card-text>
            <v-row v-show="showMenu">
                <v-col cols="12" v-for="(item, i) in items" :key="i">
                    <router-link :to="item.link" class="nav-link">
                        <v-icon>{{item.icon}}</v-icon>
                        <span class="ml-3 mr-3">{{item.text}}</span>
                    </router-link>
                </v-col>
                <v-col cols="12" class="pr-5">
                    <a class="nav-link" @click="updateSchoolModal = !updateSchoolModal">
                        <v-icon>mdi-home</v-icon>
                        <span class="ml-3 mr-3">Update School Info</span>
                    </a>
                    <update-school-info v-if="updateSchoolModal"/>
                </v-col>
                <v-col cols="12">
                    <a class="nav-link" @click="signout">
                        <v-icon>mdi-minus-circle-outline</v-icon>
                        <span class="ml-3 mr-3">Signout</span>
                    </a>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>
import UpdateSchoolInfo from './UpdateSchoolInfo.vue'
  export default {
  components: { UpdateSchoolInfo },
    name: 'UserMenuCard',
    data: () => ({
        updateSchoolModal: false,
        showMenu: true,
        items: [
            { text: 'Dashboard', icon: 'mdi-account', link: 'dashboard' },
            { text: 'Record Scores', icon: 'mdi-typewriter', link: 'scores' },
            { text: 'Print Result Sheets', icon: 'mdi-printer', link: 'sheets' },
            { text: 'Students', icon: 'mdi-account-supervisor', link: 'students' },
            { text: 'Subjects', icon: 'mdi-book-open', link: 'subjects' },
            { text: 'Classes', icon: 'mdi-google-classroom', link: 'classes' },
            { text: 'Grading System', icon: 'mdi-ab-testing', link: 'gradings' },
            { text: 'Session/Terms', icon: 'mdi-circle-slice-5', link: 'terms' },
            { text: 'Manage Multi-access', icon: 'mdi-account-lock', link: 'tokens' },
        ],
    }),
    methods: {
        signout () {
            sessionStorage.removeItem('authToken')
            localStorage.removeItem('school')
            sessionStorage.removeItem('utpc')
            sessionStorage.removeItem('accessToken')
            sessionStorage.removeItem('loggedUser')
            this.$store.commit("isLoggedIn", false);
            this.$store.commit("isTokenAccess", false);
            this.$router.push('login')
        }
    }
  }
</script>

<style scoped>
.nav-link{
    text-decoration: none;
    color: #a5a084 !important;;
}
.router-link-active{
    color: rgb(241, 255, 181) !important;
    padding: 5px;
    font-weight: 700;
}
</style>
