<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="9"
      >
            <v-card>
                <v-expansion-panels
                v-model="panel"
                multiple>
                    <v-expansion-panel class="lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">About Software</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Our Results management software is web-based, uses minimal internet connection and helps you record, view and print report cards / sheets for your students -- <a href="https://www.youtube.com/watch?v=PjH4QiOVwPM" target="_blank">How it works</a>
                            <v-card-text>
                                <v-card-title>v 4.0.0</v-card-title>
                                <ul>
                                    <li v-for="(item,i) in v4" :key="i">{{item.feature}}</li>
                                </ul>
                                <v-divider></v-divider>
                                <v-card-title>v 3.0.0</v-card-title>                                
                                <ul>
                                    <li v-for="(item,i) in v3" :key="i">{{item.feature}}</li>
                                </ul>
                                <v-divider></v-divider>
                                
                                <v-card-title>v 2.0.0</v-card-title>
                                <ul>
                                    <li v-for="(item,i) in v2" :key="i">{{item.feature}}</li>
                                </ul>
                                <v-divider></v-divider>
                                <v-card-title>v 1.0.0</v-card-title>
                                <ul>
                                    <li v-for="(item,i) in v1" :key="i">{{item.feature}}</li>
                                </ul>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                     <v-expansion-panel class="cyan lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">Partnerships, Referrals &amp; Rewards</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                We are always looking forward to partners and referrals from schools, organizations and individuals. 
                                <ul>
                                    <li><strong>Individuals:</strong> Refer a school to signup for the premium plan and earn 20% of the payments termly as long as the referred school remains on the Premium plan</li>
                                    <li><strong>Schools:</strong> Refer another school to signup for the premium plan and get 1 term Premium for FREE for your school</li>
                                    <li><strong>Organizations:</strong> Sponsor a premium plan for any school of your choice in your locality</li>
                                </ul>
                                <p class="mt-2">Send a Mail to <a href="mailto:support@mysch.ng">support@mysch.ng</a></p>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="green lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">Pricing</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-subtitle>Start FREE! See the table below for the Free vs Premium comparison</v-card-subtitle>
                            <v-card-text>
                               <v-simple-table
                               dense>
                                   <thead>
                                       <tr>
                                           <th>Features</th>
                                           <th>FREE Plan</th>
                                           <th>Premium Plan</th>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       <tr>
                                           <td>Record Scores</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Record Remarks</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Unlimited Students</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Unlimited Term/Session Results</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Cummulative Terms Results</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Broadsheet By Subjects</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Broadsheet by Students</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>New Class Promotion</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Double Class Promotion</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Custom Request Report Sheets</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Student / Parent Access</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Multiple Teacher Access</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Customer Support</td>
                                           <td><yes-green-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Technical Support</td>
                                           <td><no-red-icon/></td>
                                           <td><yes-green-icon/></td>
                                       </tr>
                                       <tr>
                                           <td>Cost: Student per Term</td>
                                           <td>0.00 NGN</td>
                                           <td>400.00 NGN</td>
                                       </tr>
                                       <tr>
                                           <td>Cost: Student per Term</td>
                                           <td>0.00 USD</td>
                                           <td>1.99 USD</td>
                                       </tr>
                                   </tbody>
                               </v-simple-table>
                               <v-btn color="primary" text small><router-link to="register"> Register Your School</router-link></v-btn>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>                    
                     <v-expansion-panel class="deep-orange lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">Result Samples</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                <img src="/sample1.JPG" height="350px"/>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="grey lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">Our Clients</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-subtitle>Join these schools using our results management software. Register your school Now!</v-card-subtitle>
                            <v-card-text>
                                <img v-for="(logo,i) in logos" :key="i" :src="logo.logo" class="mr-2" height="50"/> <v-chip>+ more schools</v-chip>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="lighten-5">
                        <v-expansion-panel-header>
                            <h3 class="black--text">Users Feedback</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6" v-for="(testi,i) in testimonials" :key="i">
                                        <v-card min-height="150">
                                        <v-card-title>
                                            <v-avatar size="36">
                                            <img
                                                alt="user"
                                                src="user.svg"
                                            >
                                            </v-avatar>
                                            <p class="ml-3 mt-3">{{testi.user}}</p>
                                        </v-card-title>
                                        <v-card-text>{{testi.message}}</v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
      </v-col>
      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="3"
      >
        <guest-menu-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import GuestMenuCard from '../components/GuestMenuCard.vue'
import HeaderVue from '../components/Header.vue'
import YesGreenIcon from '../components/sheets/Other/YesGreenIcon.vue';
import NoRedIcon from '../components/sheets/Other/NoRedIcon.vue';

  export default {
    name: 'Home',
    components: {
        GuestMenuCard,
        HeaderVue,
        YesGreenIcon,
        NoRedIcon,
    },
    data: () => ({
        authToken:localStorage.getItem('authToken') ?? null,
        logos: [],
        panel: [],
        v4:[
            {feature: 'Everything in v3.0.0 plus...'},
            {feature: 'Student to class promotion or demotion in the same session or new session'},
            {feature: 'Can now view results of past terms you have created'},
            {feature: 'Can view cumulative terms results at any time in the term'},
            {feature: 'Can now choose to show/hide result features like class position, subject position, and more...'},
            {feature: 'Can now see how many students results were printed for and how much due to pay on your dashboard'},
            {feature: ' Can deactivate students who have left the school so that they do not appear on the report sheets'},
            {feature: 'Students with no scores for a subject no longer have those subjects show up in their report sheets'},
            {feature: 'Removed support for multi-school single login'},
        ],
        v3:[
            {feature: 'Everything in v2.0.0 plus...'},
            {feature: 'Can record remarks and psychomotors for students'},
            {feature: 'Class to class students promotions in the new session'},
            {feature: 'Can view broadsheet results by subjects and by student groups'},
            {feature: 'Can request for custom results report sheets'},
            {feature: 'Can login to multi-schools you own with one login'},
        ],
        v2:[
            {feature: 'Everything in v1.0.0 plus...'},
            {feature: 'Dynamic school routing'},
        ],
        v1:[
            {feature: 'Can manage unlimited terms and sessions'},
            {feature: 'Can manage subjects by class'},
            {feature: 'Can manage students by class'},
            {feature: 'Can set grading systems'},
            {feature: 'Can enter student scores by subject'},
            {feature: 'Can print individual student results'},
        ],
        testimonials:[
            {user: 'Mary', message: 'Thank you for the prompt support from you & your team'},
            {user: 'James', message: 'Your software is so easy to use and understand'}
        ]
    }),
    mounted() {
       if (this.authToken) {
           this.$router.push('dashboard')
       }
       this.fetchSchoolLogos()
    },
    methods:{
        fetchSchoolLogos (){
            axios.get(process.env.VUE_APP_BASEURL+'/schools?logo='+1,
            {
            })
            .then((response) => {
                this.logos =  response.data.data            
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        }
    }
  }
</script>
