<template>
  <v-icon color="green">mdi-check-circle</v-icon>
</template>

<script>
export default {
    name: 'YesGreenIcon'
}
</script>

<style>

</style>