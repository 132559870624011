<template>
  <div v-if="school">
    <h1 class="text-uppercase">
        <img :src="school.logo" class="shrink mr-2" height="30"/>
        <span class="title font-weight-bold">
            {{school.name}}
        </span>
    </h1>
        <p class="caption font-weight-regular">
            {{school.motto}}
        </p>
  </div>
  <div v-else>
    <h1>
        <img src="../assets/logo.png" class="mt-3" height="50"/>
        <span class="display-1 font-weight-bold">
            MYSCH
        </span>
    </h1>
        <p class="subheading font-weight-regular">
            Your awesome School Results Manager
        </p>
  </div>
</template>

<script>
export default {
    name: 'Header',
    data: () => ({
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        stats: [
            { title: 'Students this Term', icon: 'mdi-account', count: 14 },
        ],
        record:{
            email:null,
            password:null,
        },
    }),
}
</script>