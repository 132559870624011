<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          color="#e6205a"
        >
          <v-btn
            icon
            dark
            @click="reloadPage"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">My School Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="reloadPage"
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
                <v-row>
                    <!-- Create /Update Form -->
                    <v-col cols="12">
                        <v-form v-model="valid" ref="form">
                            <v-container>
                                <v-row>                                        
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                        <v-text-field
                                            outlined
                                            v-model="school.name"
                                            label="School Name"
                                            :rules="[v => !!v || 'School Name is required']"
                                            required
                                        ></v-text-field>
                                        <v-text-field
                                            outlined
                                            v-model="school.motto"
                                            label="School Motto / Slogan"
                                            :rules="[v => !!v || 'School Motto is required']"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                        <v-text-field
                                            outlined
                                            v-model="school.email"
                                            label="School Email (optional)"
                                        ></v-text-field>
                                        <v-text-field
                                            outlined
                                            v-model="school.phone"
                                            label="School Phone (optional)"
                                        ></v-text-field>
                                        
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                        <v-text-field
                                            outlined
                                            v-model="school.address"
                                            label="School Address"
                                            :rules="[v => !!v || 'School Address is required']"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                        <v-file-input
                                            filled
                                            :rules="[value => !value || value.size < 500000 || 'Logo size should be less than 500kb!']"
                                            accept="image/png, image/jpeg, image/jpg"
                                            placeholder="School Logo"
                                            prepend-icon="mdi-camera"
                                            label="School Logo"
                                            v-model="logo"
                                            id="logo"
                                        ></v-file-input>
                                        
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                        <v-text-field
                                            outlined
                                            v-model="school.student_reg_initials"
                                            label="Student Reg. NO. Initials (optional)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                       <v-file-input
                                            filled
                                            :rules="[value => !value || value.size < 500000 || 'Logo size should be less than 500kb!']"
                                            accept="image/png, image/jpeg, image/jpg"
                                            placeholder="School Stamp/Signature"
                                            prepend-icon="mdi-camera"
                                            label="School Stamp / Signature (optional)"
                                            v-model="stamp"
                                            id="stamp"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="12"
                                    >
                                    <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecordAndImage"
                                        >
                                            Update
                                        </v-btn>                                    
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-col>
                </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
    export default {
        name: 'UpdateSchoolInfo',
        data () {
            return {
                authToken:sessionStorage.getItem('authToken') ?? null,
                school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
                dialog: true,
                valid:true,
                logo: null,
                stamp: null,
            }
        },
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
        methods: {
            uploadImage () {
                if (this.logo || this.stamp){
                    let formdata = new FormData()
                    if (this.logo) {
                        formdata.append('school_logo', document.querySelector('#logo').files[0])
                    }
                    if (this.stamp) {
                        formdata.append('school_stamp', document.querySelector('#stamp').files[0])
                    }
                    formdata.append('school_id', this.school.school_id)
                    let loader = this.$loading.show()
                    axios.post(process.env.VUE_APP_BASEURL+'/schools',formdata,
                    {
                        headers: 
                        {
                            'Authorization': 'Bearer '+this.authToken,
                            'Content-Type' : 'multipart/form-data'
                        }
                    })
                    .then((response) => {    
                        localStorage.school =  JSON.stringify(response.data.data)  
                        this.$toast.info('Image changed successfully. You might need to refresh page to see it!', {
                            timeout: 5000
                        });                     
                    })
                    .catch((error) => {
                        this.$toast.error(error.response ? error.response.data.message : 'Image upload failed. try again some few minutes time', {
                            timeout: 5000
                        });
                        console.log(error)
                    })
                    .finally(() => {
                        loader.hide()
                    });
                }
            },
            updateRecord () {
                let validation = this.$refs.form.validate()
                if(!validation) {
                    return validation
                }
                let loader = this.$loading.show()
                axios.put(process.env.VUE_APP_BASEURL+'/schools/'+this.school.school_id,this.school,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then((response) => {
                    this.$toast.success('School info sucessfully updated!', {
                        timeout: 5000
                    }); 
                    localStorage.school =  JSON.stringify(response.data.data)             
                })
                .catch((error) => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                        timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            },
            updateRecordAndImage(){
                this.uploadImage()
                this.updateRecord()
            },
            reloadPage (){
                window.location.reload();
            },
        }
  }
</script>

<style scoped>

</style>